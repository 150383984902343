import axios from "axios";

const baseUrl = "https://api.apamverse.com/";
// 新增收件人地址
function contactFormSubmission(data) {
    return new Promise(function (resolve, reject) {
        axios
            .post(
                baseUrl + "home/contact",
                {
                    contactName: data.contactName,
                    contactPhone: data.contactPhone,
                    email: data.email,
                    companyName: data.companyName,
                    companyWebsite: data.companyWebsite,
                    budget: data.budget,
                    expectedActivationTime: data.expectedActivationTime,
                    message: data.message,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                // console.log(response)
                resolve(response);
            })
            .catch((err) => reject(err));
    });
}

export {
    contactFormSubmission
}